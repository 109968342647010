import React, { useEffect, useState } from 'react';
import { Table, Card, Typography, message, Spin, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import moment from "moment";

const { Text } = Typography;

const TransactionDetail = (props) => {
  const event_id = props.match.params.id;
  const { history } = props;

  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });

  // Fetch transactions when component mounts or event_id changes
  useEffect(() => {
    if (event_id) {
      fetchTransactionDetails(1, 10);
    }
  }, [event_id]);

  // Function to fetch transaction details
  const fetchTransactionDetails = async (page, pageSize) => {
    setLoading(true);
    try {
      const payload = { event_id, page: page - 1, limit: pageSize };
      const response = await fetch('https://rallytrax.xyz:9500/api/admin/get-all-txn-detail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok && data && data.data) {
        setListData(data.data);
        setPagination({ ...pagination, total: data.total || 0, current: page });
      } else {
        message.warning(data.message || 'No transaction data found.');
      }
    } catch (error) {
      console.error('Fetch Error:', error);
      message.error('Failed to fetch transaction details.');
    } finally {
      setLoading(false);
    }
  };

  // Pagination handler
  const handleTableChange = (pagination) => {
    fetchTransactionDetails(pagination.current, pagination.pageSize);
  };

  // Columns for the transaction table
  const transactionColumns = [
    {
      title: 'S.N.',
      dataIndex: 'index',
      render: (val, data, rowIndex) => <span>{(pagination.current - 1) * pagination.pageSize + rowIndex + 1}</span>,
    },
    {
      title: 'Transaction ID',
      dataIndex: 'payment_intent_id',
      render: (val) => <span className="text-info">{val || '-'}</span>,
    },
    {
      title: 'User Email',
      dataIndex: 'user_id',
      render: (user) => <span className="text-info">{user?.email || '-'}</span>,
    },
    {
      title: 'Business Name',
      dataIndex: 'business_user_id',
      render: (business) => <span className="text-info">{business?.business_name || '-'}</span>,
    },
    {
      title: 'Business Email',
      dataIndex: 'business_user_id',
      render: (business) => <span className="text-info">{business?.email || '-'}</span>,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (val) => <span className="text-info">{val !== null ? `$${val}` : '-'}</span>,
    },
    {
      title: 'Stripe Fees',
      dataIndex: 'stripe_fees',
      render: (val) => <span className="text-info">{val !== null ? `$${val}` : '-'}</span>,
    },
    {
      title: 'Admin Fees',
      dataIndex: 'admin_fees',
      render: (val) => <span className="text-info">{val !== null ? `$${val}` : '-'}</span>,
    },
    {
      title: 'Total Amount',
      dataIndex: 'total_amount',
      render: (val) => <span className="text-info">{val !== null ? `$${val}` : '-'}</span>,
    },
    {
      title: 'Payment Status',
      dataIndex: 'payment_status',
      render: (val) => (
        <span className={val === 'SUCCESS' ? 'text-success' : 'text-danger'}>{val}</span>
      ),
    },
    {
      title: 'Paid',
      dataIndex: 'paid',
      render: (val) => (
        <span className={val ? 'text-success' : 'text-danger'}>
          {val ? 'Paid' : 'Unpaid'}
        </span>
      ),
    },    
    {
      title: 'Transaction Date',
      dataIndex: 'created_at',
      render: (val) => <span>{val ? moment(val).format("ll") : '-'}</span>,
    },
  ];

  return (
    <Card 
      title="Event Transaction Details"
      bordered={false}
      extra={
        <Button type="primary" onClick={() => history.push('/transaction-list')}>
          Back to Transactions
        </Button>
      }
    >
      {loading ? (
        <div style={{ textAlign: 'center', padding: '50px 0' }}>
          <Spin tip="Loading transactions..." />
        </div>
      ) : (
        <Table
          columns={transactionColumns}
          dataSource={listData}
          rowKey={(record) => record._id}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showSizeChanger: true,
            pageSizeOptions: ['10', '25', '50', '100'],
            showTotal: (total) => `Total ${total} transactions`,
            
          }}
          onChange={handleTableChange}
          scroll={{ x: "max-content" }} // 💡 This enables horizontal scroll
          
          
        />
      )}
    </Card>
  );
};

export default withRouter(TransactionDetail);
