import React, { Component, Suspense, lazy } from "react";
import { Route } from "react-router-dom";
import { Redirect } from "dva/router";

//DASHBOARD
import Dashboard from "../pages/dashboard/dashboard";

//USER MANAGMENT
import UsersList from "../pages/users/list";
import AddEditUser from "../pages/users/action/addEdit";

//USER MANAGMENT
import TransactionList from "../pages/transactions/list";
import AddEditTransaction from "../pages/transactions/action/addEdit";

//ACCOUNT
import Account from "../pages/account/index";

//PAGES
import PagesList from "../pages/pages/list";
import AddEditPages from "../pages/pages/action/addEdit";

//BROADCAST
import BroadcastList from "../pages/broadcast/list";
import AddEditBroadcast from "../pages/broadcast/action/addEdit";

//SETTINGS
import SiteSetting from "../pages/site-setting/list";
import Setting from "../pages/setting/setting";
import DeleteRequest from "../pages/DeleteAccount/DeleteRequest";
import TransactionDetail from "../pages/transactions/TransactionDetail";
// import TransactionDetail from "../pages/transactions/TransactionDetail";

function hasAdmin() {
  let role = localStorage.getItem("role");
  if (role === "ADMIN") {
    return true;
  } else {
    return false;
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      hasAdmin() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

class SubRoute extends Component {
  render() {
    return (
      <div>
        {/* Dashboard */}
        <Route
          exact
          name="Dashboad"
          breadcrumbName="Dashboad"
          path={"/"}
          component={Dashboard}
        />

        {/*START User List */}
        <PrivateRoute exact path="/user-regular" component={UsersList} />
        <PrivateRoute exact path="/users/edit/:id" component={AddEditUser} />
        {/*END User List */}

        {/*START User List */}
        <PrivateRoute
          exact
          path="/transaction-list"
          component={TransactionList}
        />
		  <PrivateRoute
          exact
          path="/transaction-detail/:id"
          component={TransactionDetail}
        />
        <PrivateRoute
          exact
          path="/transaction/edit/:id"
          component={AddEditTransaction}
        />
        {/*END User List */}

        {/* ACCOUNT ROUTES */}
        <Route exact path={"/account"} component={Account} />
        {/* END ROUTES */}

        {/*START PAGE ROUTES */}
        <Route exact path={"/pages"} component={PagesList} />
        <Route exact path={"/pages/add"} component={AddEditPages} />
        <PrivateRoute exact path="/pages/edit/:id" component={AddEditPages} />
        {/*END PAGE ROUTES */}

        {/*Broadcast ROUTES */}
        <Route exact path={"/broadcasts"} component={BroadcastList} />
        <Route exact path={"/broadcasts/add"} component={AddEditBroadcast} />
        <PrivateRoute
          exact
          path="/broadcasts/edit/:id"
          component={AddEditBroadcast}
        />
        {/*Broadcast ROUTES */}

        {/* SETTING ROUTES */}
        <Route exact path={`/settings`} component={SiteSetting} />
        <Route exact path={`/setting`} component={Setting} />
        {/* END SETTING ROUTES */}
        <Route exact path={`/delete-request`} component={DeleteRequest} />
      </div>
    );
  }
}

export default SubRoute;
