import React from "react";
import { connect } from "dva";
import {
  Empty,
  Card,
  Typography,
  Alert,
  Input,
  Button,
  Table,
  Radio,
  Divider,
  Switch,
  Row,
  Col,
  Avatar,
  Pagination,
  Tabs,
  Modal,
  Popconfirm,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { message, Spin } from "antd";

import jwt_decode from "jwt-decode";
import fetch from "dva/fetch";
import AddEdit from "./action/addEdit";
import moment from "moment";
import { withRouter } from "react-router-dom"; // ✅ Correct

const { Search } = Input;
const { Text } = Typography;
const { TabPane } = Tabs;
//const baseUrl = process.env.REACT_APP_ApiUrl

class TransactionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      Addcount: 0,
      limit: 25,
      current: 1,
      searchText: "",
      loader: false,
      detail: "",
      addModel: false,
      listData: [],
      data: [],
      pagination: { current: 1, pageSize: 10 },
      loading: false,
      sortBy: "asc",
      inactive: false,
    };
    setTimeout(() => (document.title = "Tansaction List"), 100);
  }
  componentDidMount() {
    this.ListFun();
    // this.props.dispatch({ type: 'tag/clearAction' });
  }

  ListFun = () => {
    const user = jwt_decode(localStorage.getItem("token"));
    if (user.role === "ADMIN") {
      let search =
        "page=" +
        (this.state.current - 1) +
        "&limit=" +
        this.state.limit +
        "&inactive=" +
        this.state.inactive +
        "&searchText=" +
        this.state.searchText +
        "&sortBy=" +
        this.state.sortBy;
      localStorage.setItem("newsSearch", JSON.stringify(this.state));
      let searchval = {
        page: this.state.current - 1,
        limit: this.state.limit,
        inactive: this.state.inactive,
        searchText: this.state.searchText,
        sortBy: this.state.sortBy,
      };
      console.log(
        "test",
        this.props.dispatch({ type: "txn/txnList", payload: searchval })
      );
    }
  };
  // View Detail Function
  // View Detail Function
  viewDetail = (record) => {
    console.log("View detail for:", record);
    this.props.history.push(`/transaction-detail/${record._id}`);
  };

  // Mark as Paid Function
  markAsPaid = async (record) => {
    try {
      const payload = {
        event_id: record._id,
        amount: record.total_transaction_amount,
      };
      const response = await fetch(
        "https://rallytrax.xyz:9500/api/admin/mark-paid",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();
      console.log("dataaa", data);
      if (data.status == 200 && data) {
        console.log("response", response);
        message.success("Transaction approved and marked as paid!");
        // Optionally refresh the list after successful payment
        this.ListFun();
      } else {
        message.warning(data.message || "No transaction data found.");
      }
    } catch (error) {
      console.error("Fetch Error:", error);
      message.error("Failed to process payment. Please try again later.");
    }
  };

  ShowSizeChange = (current, size) =>
    this.setState({ limit: size }, () => this.ListFun());
  switchFun = (val) => this.setState({ inactive: val }, () => this.ListFun());
  ChangeOrder = (val) =>
    this.setState(
      { sortBy: this.state.sortBy === "asc" ? "desc" : "asc" },
      () => this.ListFun()
    );
  paginationFun = (val) =>
    this.setState({ current: val.current }, () => this.ListFun());

  searchVal = (val) => {
    this.setState({ searchText: val }, () => {
      const { txn } = this.props;
      const filteredData = (txn.list?.data || []).filter((item) => {
        const businessName = item.user_id?.business_name || "";
        const mobileNumber = item.user_id?.mobile_number || "";
        const eventName = item.event_name || "";

        const searchValue = val.toLowerCase();

        return (
          businessName.toLowerCase().includes(searchValue) ||
          mobileNumber.toLowerCase().includes(searchValue) ||
          eventName.toLowerCase().includes(searchValue)
        );
      });

      this.setState({ listData: filteredData });
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot) {
      this.ListFun();
    }
  }

  createCat = (val) => {
    console.log(val);
    if (val) {
      this.ListFun();
    }
    this.setState({ detail: "", addModel: false });
  };

  deleteCat = (id) => {
    this.props.dispatch({ type: "tag/tagDelete", payload: { _id: id } });
  };

  render() {
    const { loading, addModel, detail, searchText } = this.state;
    console.log("this.state", this.state);
    const { txn } = this.props;
    const total = 0;
    const totalActive = 0;
    if (this.state.searchText == "") {
      this.state.listData = txn.list ? txn.list.data : [];
    }

    const productsAvilabilityColumns = [
      {
        title: <strong>S.N.</strong>,
        dataIndex: "index",
        render: (val, data, rowIndex) => (
          <span className="text-info">{rowIndex + 1}</span>
        ),
      },
      {
        title: <strong>Event Name</strong>,
        dataIndex: "event_name",
        render: (val, data) =>
          data.event_name ? (
            <span className="text-info">{data.event_name}</span>
          ) : (
            "-"
          ),
      },
      {
        title: <strong>Event Address</strong>,
        dataIndex: "event_address",
        render: (val, data) =>
          data.event_address ? (
            <span className="text-info">{data.event_address}</span>
          ) : (
            "-"
          ),
      },
      {
        title: <strong>Business Name</strong>,
        dataIndex: "business_name",
        render: (val, data) =>
          data.user_id.business_name ? (
            <span className="text-info">{data.user_id.business_name}</span>
          ) : (
            "-"
          ),
      },
      {
        title: <strong>Deposit type</strong>,
        dataIndex: "deposit_type",
        render: (val, data) =>
          data.user_id.deposit_type ? (
            <span className="text-info">{data.user_id.deposit_type}</span>
          ) : (
            "-"
          ),
      },
      {
        title: <strong>Routing Number</strong>,
        dataIndex: "routing_number",
        render: (val, data) =>
          data.user_id.routing_number ? (
            <span className="text-info">{data.user_id.routing_number}</span>
          ) : (
            "-"
          ),
      },
      {
        title: <strong>Account Number</strong>,
        dataIndex: "account_number",
        render: (val, data) =>
          data.user_id.account_number ? (
            <span className="text-info">{data.user_id.account_number}</span>
          ) : (
            "-"
          ),
      },
      {
        title: <strong>Business Mobile</strong>,
        dataIndex: "mobile_number",
        render: (val, data) =>
          data.user_id.mobile_number ? (
            <span className="text-info">{data.user_id.mobile_number}</span>
          ) : (
            "-"
          ),
      },
      {
        title: <strong>Total Amount</strong>,
        dataIndex: "total_transaction_amount",
        render: (val, data) =>
          data.total_transaction_amount !== null ? (
            <span className="text-info">${data.total_transaction_amount}</span>
          ) : (
            "-"
          ),
      },
      {
        title: <strong>Event Date</strong>,
        dataIndex: "event_date",
        render: (val, data) =>
          data.event_date ? (
            <span className="text-info">
              {moment(data.event_date).format("ll")}
            </span>
          ) : (
            "-"
          ),
      },
      {
        title: <strong>Action</strong>,
        dataIndex: "action",
        render: (val, data) => (
          <div style={{ display: "flex", gap: "8px" }}>
            {/* View Detail Button */}
            <Button
              size="small"
              type="primary"
              onClick={() => this.viewDetail(data)}
            >
              View Detail
            </Button>

            {/* Paid to Business Button (conditionally disabled/enabled based on amount) */}
            {data.total_transaction_amount === 0 ? (
              <Button size="small" type="default" disabled>
                Paid to Business
              </Button>
            ) : (
              <Popconfirm
                title="Are you sure you want to mark as Paid?"
                icon={<ExclamationCircleOutlined />}
                onConfirm={() => this.markAsPaid(data)}
                okText="Yes"
                cancelText="No"
              >
                <Button size="small" type="dashed">
                  Paid to Business
                </Button>
              </Popconfirm>
            )}
          </div>
        ),
      },
    ];

    return (
      <>
        <Card>
          <Row
            style={{ marginBottom: "0.625rem" }}
            className="TopActionBar"
            gutter={[16, 0]}
            justify="space-between"
            align="middle"
          >
            <Col>
              <Search
                placeholder="Search..."
                loading={this.props.submitting}
                onChange={(e) => this.searchVal(e.target.value)}
                value={searchText}
              />
            </Col>
          </Row>
          <Table
            columns={productsAvilabilityColumns}
            rowKey={(record) => record._id}
            dataSource={this.state.listData}
            onChange={this.paginationFun}
            pagination={{
              position: ["bottomLeft"],
              showTotal: (total, range) => (
                <Text type="secondary">{`Showing ${range[0]}-${range[1]} of ${total}`}</Text>
              ),
              showSizeChanger: true,
              responsive: true,
              onShowSizeChange: (current, size) =>
                this.ShowSizeChange(current, size),
              pageSizeOptions: ["25", "50", "100", "250", "500"],
            }}
            scroll={{ x: "max-content" }} // 💡 This enables horizontal scroll
          />
        </Card>
        {/* <AddEdit visible={addModel} returnData={this.createCat} closeModel={() => this.setState({ addModel: false, detail: '' })} detail={detail} /> */}
      </>
    );
  }
}

export default connect(({ txn, loading }) => ({
  txn,
  loading,
}))(withRouter(TransactionList));
